import React from "react"
import Recaptcha from "react-google-recaptcha"
import { navigate } from "gatsby"
// @material-ui/icons
import Phone from "@material-ui/icons/Phone"
// core components
import GridContainer from "../Grid/GridContainer.jsx"
import GridItem from "../Grid/GridItem.jsx"
import Button from "../CustomButtons/Button.jsx"
import Card from "../Card/Card.jsx"
import CustomInput from "../CustomInput/CustomInput.jsx"
import InfoArea from "../InfoArea/InfoArea.jsx"
// styles
import javascriptStyles from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import CardHeader from "../Card/CardHeader"
import { Input } from "@material-ui/core"

const style = {
  cardBorder: {
    marginBottom: "30px",
    padding: "20px",
  },
  titleCenter: {
    textAlign: "center",
    padding: "20px",
  },
}

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Contact = props => {
  const { classes } = props
  const [state, setState] = React.useState({})
  const recaptchaRef = React.createRef()

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        "g-recaptcha-response": recaptchaValue,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <GridContainer
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ padding: "1.5rem 16px" }}
    >
      <Card style={style.cardBorder}>
        <CardHeader
          plain
          color="primary"
          className={`${classes.textCenter} ${classes.cardLoginHeader}`}
        >
          <h5 className={classes.cardTitleWhite}>Contact Us Now!</h5>
        </CardHeader>
        <GridContainer>
          <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
            <InfoArea
              className={classes.infoArea}
              title="Contact Center Consoles Only"
              description={
                <p>
                  We're here for you. Just reach out whether you're selling your
                  boat, buying a boat, looking for advice or looking for a good
                  place to get work done to your boat!
                  <br />
                  <br />
                  <strong>Email: support@centerconsolesonly.net</strong>
                  <br />
                  <br />
                  <strong>Phone: (305) 282-2680</strong>
                  <br />
                  <br />
                  <strong>Social: @CenterConsolesOnly</strong>
                </p>
              }
              icon={Phone}
              iconColor="info"
            />
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.mrAuto}>
            <form
              name="contact"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              data-netlify-recaptcha="true"
              action="/thank-you"
              onSubmit={handleSubmit}
              className={classes.form}
            >
              <noscript>
                <p>This form won’t work with Javascript disabled</p>
              </noscript>

              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  required: true,
                }}
                inputProps={{
                  onChange: handleChange,
                  type: "text",
                  className: "form-control",
                  name: "name",
                  placeholder: "Name...",
                  autoFocus: false,
                }}
              />
              <CustomInput
                labelText="Email"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  required: true,
                }}
                inputProps={{
                  onChange: handleChange,
                  name: "email",
                  type: "text",
                  className: "form-control",
                  placeholder: "Email...",
                  autoFocus: false,
                }}
              />
              <CustomInput
                labelText="Phone Number"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  required: false,
                }}
                inputProps={{
                  onChange: handleChange,
                  name: "phone",
                  type: "tel",
                  className: "form-control",
                  placeholder: "Phone Number...",
                  autoFocus: false,
                }}
              />
              <CustomInput
                labelText="Subject"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                  required: true,
                }}
                inputProps={{
                  onChange: handleChange,
                  name: "subject",
                  type: "text",
                  className: "form-control",
                  placeholder: "Subject...",
                  autoFocus: false,
                }}
              />
              <Input
                label="Description"
                type="text"
                name="message"
                onChange={handleChange}
                placeholder={"Some great question / comment here..."}
                style={{ width: "100%" }}
                required={true}
                inputProps={{ maxLength: 5000 }}
                multiline={true}
                rows={10}
              />
              <input type="hidden" name="form-name" value="contact" />
              <Recaptcha
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                style={{ textAlign: "-webkit-center", margin: "20px" }}
              />
              <div className={classes.textCenter}>
                <Button type="submit" round color="primary">
                  Send your message now
                </Button>
              </div>
            </form>
          </GridItem>
        </GridContainer>
      </Card>
    </GridContainer>
  )
}

export default withStyles(javascriptStyles)(Contact)
