import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/Contact"

const ContactPage = () => {

  return (
    <Layout>
      <SEO title="Contact CCO" />
      <Contact />
    </Layout>
  )
}
export default ContactPage
